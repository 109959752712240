export default {
  api: {
    operationSucceed: 'Operation succeed',
    operationFailed: 'Operation failed',
    errorTip: 'Error Tip',
    errorMessage: 'Operation failed. System abnormal!',
    timeoutMessage: 'Login timed out. Please login again!',
    apiTimeoutMessage: 'Interface request timed out. Please refresh the page and try again!',
    apiRequestFailed: 'Request error. Please try again later!',
    networkException: 'Network abnormal',
    networkExceptionMsg: 'Network abnormal. Please check your network connection!',

    errMsg401: 'User has no permissions (incorrect token, username, or password)!',
    errMsg403: 'User has the permission but the access is denied!',
    errMsg404: 'Network request error. Not found the resource!',
    errMsg405: 'Network request error. Request way not allowed!',
    errMsg408: 'Network request timed out!',
    errMsg500: 'Server error. Please contact the administrator!',
    errMsg501: 'Network not implemented!',
    errMsg502: 'Network Error!',
    errMsg503: 'Service not available. Server overloaded or under maintenance!',
    errMsg504: 'Network timeout!',
    errMsg505: 'The http version does not support the request!',
  },
  exception: {
    backLogin: 'Back to Login',
    backHome: 'Back to Home',
    subTitle403: 'Sorry, you are not allowed to visit this page.',
    subTitle404: "Sorry, the page you want to visit doesn't exist.",
    subTitle500: 'Sorry, the server is reporting an error.',
    noDataTitle: 'No data on the current page.',
    networkErrorTitle: 'Network Error',
    networkErrorSubTitle: 'Sorry, network connection lost. Please check your network!',
  },
  app: {
    title: 'Ace Shop',
    description: 'Ace Shop, Purchase, Dmen Purchase, Token Purchase',
    tip: 'Tips',
    copyright: 'Ace Playing',
    icp: ' BJ ICP No.20009004',
    address: 'Address: No.28, North 3rd Ring East Road, Chaoyang District, Beijing',
    account: 'My Account',
    changePwd: 'Change Password',
    chargeRecord: 'Purchase History',
    helpCenter: 'Help Center',
    chargeNow: 'Purchase Now',
  },
  login: {
    email: 'E-mail',
    verifyCode: 'Verification Code',
    password: 'Password',
    passwordHolder: 'Please enter a combination of 8-30 numbers and letters',
    loginButton: 'Login',
    freeAccount: 'Free Registration',
    freeAccountCreate: 'Create Account Free',
    forgetPassword: 'Forget Password?',
    findPassword: 'Retrieve Password',
    findEmailSent:
      'A password retrieval email has been sent to your mailbox. Please click the link in the email to reset your password',
    forgetFormTitle: 'Reset Password',
    oldPassword: 'Old Password',
    newPassword: 'New password',
    repeatPassword: 'Please enter your password again',
    policyCheck: 'Please Agree',
    policy: '《Ace Playing Privacy Agreement》',
    hadAccount: 'Already have an account ?',
    backSignIn: 'Back to sign in',

    logoutButton: 'Exit Login',
    registerButton: 'Register',

    otherSignIn: 'Other Login Options',

    // notify
    loginSucceed: 'Login successful',
    logoutSucceed: 'You have quit the current account',
    registerSucceed: 'Registration successful',
    changePwdSucceed: 'Password successfully changed',
    resetPwdSucceed: 'Password successfully reset',

    // ValidateMsg
    emailValidateMsg: 'Please enter the correct e-mail address',
    passwordValidateMsg: 'Please enter a combination of 8-30 numbers and letters',
    verifySentMsg: 'Verification code has been sent',
    verifyValidateMsg: 'Please enter the 6-digit verification code',
    policyValidateMsg: 'Agree to sign up',
    diffPwdValidateMsg: "Passwords don't match",
  },
  help: {
    helpModalTitle: 'Help Center',
    helpTipsTitle: 'Tips',
    helpTips_1:
      'The virtual items purchased in this platform are only applicable to "D-MEN: The Defenders".',
    helpTips_2:
      "when you place an order, please carefully read and double check the item's name, price, currency, quantity, specification, details, order process, delivery info, contact info, payment method and other info. To avoid loss, please do not tell your personal info to others.",
    serviceTitle: 'Service Description',
    service_1:
      'This service is only available for users who have bound the "D-MEN: The Defenders" APP. Users need to bind the game first before they can use this service.',
    service_2:
      'Note: After entering the account number, please carefully check the displayed account info. Please proceed to the next step after confirming that they are correct! (Please not that if you purchase items for an incorrect account, we will not refund your money!)',
    service_3:
      'After the payment is successful, the system will send the corresponding virtual items to your game account. Please check them in game. If you do not receive the purchased virtual items within 30 minutes, please contact customer service through the following ways, and we will solve the problem for you in time.',
    contactTitle: 'Customer Service Channels',
    contactEmail: 'Customer Service Mailbox',
    contactFacebook: 'Facebook Link',
    contactDiscord: 'Discord Link',
  },
  charge: {
    title: 'Item List',
    description: 'Ace Shop, Purchase, Dmen Purchase, Token Purchase',
    coinSelect: 'Currency Selection',
    merchandiseList: 'Game Item List',
    buyNow: 'Buy Now',
    quickLimit: 'Flash Sale',
    buyLimit: 'Limited offer: {limit}',
    chooseRole: 'Select Character',
    server: 'Server',
    serverIdHolder: 'Please enter the server name',
    roleID: 'Character ID',
    roleIDHolder: 'Please enter the character ID',
    searchResult: 'Search Results',
    searchAgain: 'Search Again',
    addRole: 'Add character',
    roleInfo: 'Character Info',
    roleLevel: 'Lv. {level}',
    gameName: 'Game Name',
    action: 'Operation',
    confirmDel: 'Confirm delete?',
    paymentWay: 'Payment Options',
    paymentAmount: 'Amount',
    roleLimit: 'Remaining purchases: ',
    payNow: 'Pay Now',
    payJumpTips: 'Going to jump to a third party payment processor. Please wait...',
    paid: 'Payment completed',
  },
  orders: {
    title: 'Order List',
    description: 'Order List',
    ordersTitle: 'Order List',
    searchDate: 'Query Date',
    selectGame: 'Select Game',
    allGame: 'All Games',
    orderNo: 'Order ID',
    gameName: 'Game Name',
    merchandiseName: 'Item Name',
    merchandiseDetail: 'Item Details',
    orderAmount: 'Order Amount',
    createAt: 'Creation Time',
    orderStatus: 'Order Status',
    statusGoing: 'Payment in progress',
    statusSucceed: 'Payment successful',
    statusUnknown: 'Please go to the recharge record to view the payment result',
    statusFailed: 'Payment failed',
    action: 'Operation',
    viewDetail: 'View Details',
    detailTitle: 'Order Details',
    backToOrders: 'Back to Order List',
    orderInfo: 'Order Info',
    merchandiseInfo: 'Item Info',
    price: 'Unit Price',
    quantity: 'Quantity',
    discount: 'Discount',
    payResult: 'Payment Result',
    viewOrder: 'View Order',
  },
  order: {
    title: 'Order Details',
    description: 'Order Details',
  },
  notFound: {
    title: '404',
    description: '404',
  },
  result: {
    title: 'Payment Result',
    description: 'Payment Result',
  },
};
